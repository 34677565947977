import React, { useEffect } from 'react';
import { colors } from '../../Components/colors';
import Payouts from './payouts';
export default function HowToPlay() {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      window.scrollTo(0, 0)
    }
  }, [])
  return (
    <div
      style={{ minHeight: "50vh" }}
    >
      <div style={{ position: "absolute" }}
        className="second-heading-style center-style"
      >
        <div className="" style={{ textAlign: "center" }}>
          <h1 style={{ color: "#fff", fontWeight: "bold", marginBottom: "10px" }}>HOW TO PLAY</h1>
          <h2 style={{ color: colors.color_green4 }}>SIMPLE, FAST & FUN</h2>
        </div>
      </div>
      <img src="/assets/images/dice2.png"
        className="second-heading-style"
      />
      <div className="center-style">
        <h2 className="how-to-play-headings">16 Games </h2>
      </div>
      <div className="center-style">
        <img src={'assets/images/logo.png'}
          width={"100px"}
          height={"100px"}
        />
      </div>
      <div className="center-style">
        <h2 className="how-to-play-headings">70% Payout </h2>
      </div>
      <div className="center-style">
        <img src={'assets/images/logo.png'}
          width={"100px"}
          height={"100px"}
        />
      </div>
      <div className="center-style">
        <h2 className="how-to-play-headings">30% Win </h2>
      </div>
      <div className="center-style">
        <img src={'assets/images/logo.png'}
          width={"100px"}
          height={"100px"}
        />
      </div>
      <div className="center-style">
        <h2 className="how-to-play-headings">Every. Time. </h2>
      </div>

      {/* 4 buttons */}
      <div className="row">
        <div className="col-1" />
        <div className="row col-10" x>
          <div className="col-lg-3 col-sm-6">
            <div
              className="inner-button-style-how ">
              <div className="center-style">
                <img
                  src="/assets/images/howtoTicket.png"
                  className="how-to-button-image"
                />
              </div>
              <div className="center-style">
                <div>
                  <div className="how-to-button-heading">Mint or Buy Tickets</div>
                  <div className="how-to-button-content" >
                    but also the leap into electronic typesetting, remaining essentially
                  </div>
                  <div className="how-to-button-button">Mint Now  {'>  >'} </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className="inner-button-style-how ">
              <div className="center-style">
                <img
                  src="/assets/images/howtolottery.png"
                  className="how-to-button-image"
                />
              </div>
              <div className="center-style">
                <div>
                  <div className="how-to-button-heading">Participate Through Dashboard </div>
                  <div className="how-to-button-content" >
                    but also the leap into electronic typesetting, remaining essentially
                  </div>
                  <div className="how-to-button-button">Mint Now  {'>  >'} </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className="inner-button-style-how ">
              <div className="center-style">
                <img
                  src="/assets/images/howtoWin.png"
                  className="how-to-button-image"

                />
              </div>
              <div className="center-style">
                <div>
                  <div className="how-to-button-heading">Winners Are Selected on End Time</div>
                  <div className="how-to-button-content" >
                    but also the leap into electronic typesetting, remaining essentially
                  </div>
                  <div className="how-to-button-button">Mint Now  {'>  >'} </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className="inner-button-style-how ">
              <div className="center-style">
                <img
                  src="/assets/images/howto.png"
                  className="how-to-button-image"

                />
              </div>
              <div className="center-style">
                <div>
                  <div className="how-to-button-heading">View Results and Collect The Winnings</div>
                  <div className="how-to-button-content">
                    but also the leap into electronic typesetting, remaining essentially
                  </div>
                  <div className="how-to-button-button">Mint Now  {'>  >'} </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Games */}
      <div style={{ minHeight: "80vh" }} className="center-style">
        <div>
          <h2 className="common-heading">Games</h2>
          <table>
            <thead>
              <tr >
                <th style={{ backgroundColor: "#444" }} className="games-class">Game</th>
                <th style={{ backgroundColor: "#444" }} className="number-of-tickets-class">Number of Ticket for one entry</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="games-class">GAME A</td>
                <td className="number-of-tickets-class">1 NFT Lottery Ticket = 1 Entry</td>
              </tr>
              <tr>
                <td className="games-class">GAME B</td>
                <td className="number-of-tickets-class">1 NFT Lottery Ticket = 1 Entry</td>
              </tr>
              <tr>
                <td className="games-class">GAME C</td>
                <td className="number-of-tickets-class">1 NFT Lottery Ticket = 1 Entry</td>
              </tr>
              <tr>
                <td className="games-class">GAME D</td>
                <td className="number-of-tickets-class">1 NFT Lottery Ticket = 1 Entry</td>
              </tr>
            </tbody>
          </table>


          <table style={{ margin: "40px 0" }}>
            <thead>
              <tr >
                <th style={{ backgroundColor: "#444" }} className="sub-games-class">Sub-Game</th>
                <th style={{ backgroundColor: "#444" }} className="total-entrants">Total Entrants</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="sub-games-class">A.1, B.1, C.1, D.1</td>
                <td className="total-entrants">1000 Entrants</td>
              </tr>
              <tr>
                <td className="sub-games-class">A.1, B.1, C.1, D.1</td>
                <td className="total-entrants">1000 Entrants</td>
              </tr>
              <tr>
                <td className="sub-games-class">A.1, B.1, C.1, D.1</td>
                <td className="total-entrants">1000 Entrants</td>
              </tr>
              <tr>
                <td className="sub-games-class">A.1, B.1, C.1, D.1</td>
                <td className="total-entrants">1000 Entrants</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div style={{ minHeight: "100vh" }} className="center-style">
        <div>
          <h2 className="common-heading">Payouts</h2>
          <Payouts />
          {/* <img
            src="/assets/images/payouts.png"
            style={{ objectFit: "contain", width: "80vw" }}
          /> */}
        </div>
      </div>

      <div style={{ minHeight: "80vh" }} className="center-style">
        <div>
          <h2 className="common-heading">Process</h2>
          <div className="row">
            <div className="process-how-to">
              <div style={{ padding: "20px", backgroundColor: "#fff", borderRadius: "20px", fontSize: "18px", fontWeight: "bold" }}>
                <ol>
                  <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                  <li> Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</li>
                  <li> Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
                  <li> Curabitur tempus urna at turpis condimentum lobortis.</li>
                  <li> Ut commodo efficitur neque.</li>
                  <li> Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
                  <li> Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
                </ol>
              </div>
              <div style={{ margin: "70px 0 0", textAlign: "center", fontSize: "20px", color: "#490E67", fontWeight: "800" }}>
                Winners Are Always Selected Randomly Using Chainlink VRF
              </div>
              <div style={{ textAlign: "right", fontSize: "16px", color: "#999" }}>
                Learn More {">>"}
              </div>
            </div>

          </div>
        </div>
      </div>
      <style jsx>{`
      table {
        font-family: arial, sans-serif;
        border: 0.5px solid #eee;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #000;
        text-align: left;
        padding: 5px 8px;
      }
      
      th {
        background-color: #fff;
        border: 1.5px solid #000;
      }
      
      thead {
        background-color: #888;
        color: #fff;
      }
      
      tbody {
        border: 1.5px solid #000;
      }
      
      tr:nth-child(odd) {
        background-color: #ddd;
        overflow-x: scroll;
      }
      
      tr:nth-child(even) {
        background-color: #fff;
        overflow-x: scroll;
      }
      
      `}</style>
    </div >
  )
}
import React from 'react';
import CurrentRunningGames from './Components/CurrentRunningGames';
import Winners from './Components/Winners';

export default function LandingPage(props) {
  return (
    <div>
      {/* banner */}
      <div>
        <div style={{
          position: "absolute",
          backgroundColor: "#00000055",
          width: "100vw",
          height: "85vh",
        }}
          className="center-style"
        >
          <div>
            <div style={{ fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
              <span style={{ color: "#C9D2DF" }}>Mint&nbsp;</span>
              <span style={{ color: "#ffffff" }}>Play&nbsp;</span>
              <span style={{ color: "#FFD600" }}>Win</span>
            </div>
            <div style={{ color: "#fff", fontSize: "35px", textAlign: "center" }}>
              a decentalized lottery with guaranteed winners
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="home-button">
                Mint
              </div>
            </div>
          </div>
        </div>

        <img src={'assets/images/777poster.png'}
          style={{ width: "100vw", height: "85vh", objectFit: "cover" }}
        />

      </div>

      {/* our winners */}
      <Winners />
      <CurrentRunningGames />

      {/* bottom banner */}
      <div className="row" style={{ minHeight: "100vh" }}>
        <div className="col-1" />
        <div className="col-10 row">
          <div className="col-md-4" style={{ padding: "20px",display:"flex",justifyContent:"center" }}>
            <img src="assets/images/bigWin.png"
              style={{ height: "80vh" }}
            />
          </div>
          <div className="col-md-4" style={{ padding: "20px",display:"flex",justifyContent:"center" }}>
            <img src="assets/images/bigWin.png"
              style={{ height: "80vh" }}
            />
          </div>
          <div className="col-md-4" style={{ padding: "20px",display:"flex",justifyContent:"center" }}>
            <img src="assets/images/bigWin.png"
              style={{ height: "80vh" }}
            />
          </div>
        </div>
      </div>


    </div>
  )
}
import React, { useEffect } from 'react';

export default function Partners() {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   return () => {
  //     window.scrollTo(0, 0)
  //   }
  // }, [])
  return (
    <div
      style={{ minHeight: "50vh" }}
    >
      <div style={{ position: "absolute" }}
        className="second-heading-style center-style"
      >
        <div className="" style={{ textAlign: "center" }}>
          <h1 style={{ color: "#490E67", fontWeight: "bold", marginBottom: "10px" }}>PARTNERS</h1>
        </div>
      </div>
      <img src="/assets/images/dice2.png"
        className="second-heading-style"
      />
      <div className="center-style" style={{ margin: "50px 0 0px" }}>
        <h2 className="common-heading">Become our Partners </h2>
      </div>
      <div className="row" style={{ margin: "0 20px 50px" }}>
        <div className="col-2" />
        <div className="col-8" style={{ fontSize: "25px", textAlign: "center" }}>
          <br />endum lorem. Morbi convallis convallis diam sit amet lacinia.
          <br />Aliquam in elementum tellus.endum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
          <br />endum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
          <br />endum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
          <br />endum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
          <br />endum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
        </div>
      </div>

      {/* contact form */}
      <div style={{ minHeight: "100vh" }} className="center-style">
        <div className="contact-form center-style">
          <div>
            <h3>Contact US:</h3>
            <div className="row">
              <div className="col-lg-6">
                <br />
                <label className="contact-label">Name</label>
                <input className="contact-input col-12"
                  placeholder="Name"
                />
              </div>
              <div className="col-lg-6">
                <br />
                <label className="contact-label">Number</label>
                <input className="contact-input col-12"
                  placeholder="Number"
                />
              </div>
              <div className="col-lg-6">
                <br />
                <label className="contact-label">Email</label>
                <input className="contact-input col-12"
                  placeholder="Email"
                />
              </div>
              <div className="col-lg-6">
                <br />
                <label className="contact-label">Brand</label>
                <input className="contact-input col-12"
                  placeholder="Brand"
                />
              </div>
              <div className="col-12">
                <br />
                <label className="contact-label">Postition</label><br />
                <input className="contact-input col-12"
                  placeholder="Brand"
                />
              </div>
              <div className="col-12">
                <br />
                <label className="contact-label">Postition</label><br />
                <textarea className="contact-input col-12"
                  cols={30}
                  rows={5}
                  placeholder="Brand"
                />
              </div>
              <div className="col-12">
                <div className="center-style ">
                  <span style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#48039C"
                  }}>
                    Submit
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Trusted  */}
      <div style={{ minHeight: "50vh" }} className="center-style">
        <div>
          <div className="center-style" style={{ margin: "30px 0" }}>
            <h2 className="common-heading">Trusted by: </h2>
          </div>
          <div className="row" style={{ width: "100vw" }}>
            <div className="col-1" />
            <div className="col-10">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <img src="assets/images/PolygonLogo.png" />
                </div>
                <div className="col-lg-3 col-6">
                  <img src="assets/images/PolygonLogo.png" />
                </div>
                <div className="col-lg-3 col-6">
                  <img src="assets/images/PolygonLogo.png" />
                </div>
                <div className="col-lg-3 col-6">
                  <img src="assets/images/PolygonLogo.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100vw", marginTop: "30px" }}>
            <div className="col-2" />
            <div className="col-8">
              <div className="row">
                <div className="col-lg-4 col-6">
                  <img src="assets/images/PolygonLogo.png" />
                </div>
                <div className="col-lg-4 col-6">
                  <img src="assets/images/PolygonLogo.png" />
                </div>
                <div className="col-lg-4 col-6">
                  <img src="assets/images/PolygonLogo.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}
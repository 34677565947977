import { combineReducers } from 'redux';
import { LOGIN, LOGOUT, RETRIEVE_TOKEN } from './types';

const initialLoginState = {
  isLoading: true,
  id: null,
};

const LoginState = (state = initialLoginState, action) => {
  switch (action.type) {
    case RETRIEVE_TOKEN:
      return {
        ...initialLoginState,
        isLoading: false,
        id: action.id,
      };
    case LOGIN:
      return {
        ...initialLoginState,
        isLoading: false,
        id: action.id,
      };
    case LOGOUT:
      return {
        ...initialLoginState,
        isLoading: false,
      };
    default:
      return state
  }
}

export default combineReducers({
  LoginState: LoginState,
});


import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div className="header-container"
    >
      <div className="logo-container">
        <Link className="nav1 Link"
          to="/"
        >
          <img src={'assets/images/logo.png'}
            width={"50px"}
            height={"50px"}
          />
        </Link>
      </div>
      <div className="nav-container">
        <div className="nav-container-outer">
          <div className="nav-container-inner">
            <div className="nav1">
              <span>
                Play Lottery
              </span>
            </div>
            <Link className="nav1 Link"
              to="how-to-play"
            >
              <span>
                How to Play
              </span>
            </Link>
            <Link className="nav1 Link"
              to="dashboard"
            >
              <span>
                Your Dashboard
              </span>
            </Link>
            <Link className="nav1 Link"
              to="faq"
            >
              <span>
                FAQs
              </span>
            </Link>
            <div className="nav2">
              <span>
                LAUNCH DAPP
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import { LOGIN, LOGOUT, RETRIEVE_TOKEN, } from './types';

export const login = (
  id,
) => {
  localStorage.setItem('id', id);
  return ({
    type: LOGIN,
    id: id,
    isLoading: false
  })
}
export const logout = () => {
  localStorage.clear();
  return {
    type: LOGOUT,
    id: null,
    isLoading: false
  }
}
export const getUser = () => {
  const id = localStorage.getItem('id');
  return {
    type: RETRIEVE_TOKEN,
    id: id,
    isLoading: false
  }
}


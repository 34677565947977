import React from 'react';

const FAQQuestions = [
  {
    "question": "What is a Payment Gateway?",
    "answer": `
A payment gateway is an ecommerce service that processes online payments for online as well as offline businesses. Payment gateways help accept payments by transferring key information from their merchant websites to issuing banks, card associations and online wallet players.
Payment gateways play a vital role in the online transaction process, which is the realisation of value, and hence are seen as an important pillar of ecommerce.
`,
  },
  {
    "question": "Do I need to pay to Instapay even when there is no transaction going on in my business?",
    "answer": `
    A payment gateway is an ecommerce service that processes online payments for online as well as offline businesses. Payment gateways help accept payments by transferring key information from their merchant websites to issuing banks, card associations and online wallet players.
    Payment gateways play a vital role in the online transaction process, which is the realisation of value, and hence are seen as an important pillar of ecommerce.
    `,
  },
  {
    "question": "What platforms does Instapay payment gateway support?",
    "answer": `
    A payment gateway is an ecommerce service that processes online payments for online as well as offline businesses. Payment gateways help accept payments by transferring key information from their merchant websites to issuing banks, card associations and online wallet players.
    Payment gateways play a vital role in the online transaction process, which is the realisation of value, and hence are seen as an important pillar of ecommerce.
    `,
  },
  {
    "question": "Does Instapay provide international payments support?",
    "answer": `
    A payment gateway is an ecommerce service that processes online payments for online as well as offline businesses. Payment gateways help accept payments by transferring key information from their merchant websites to issuing banks, card associations and online wallet players.
    Payment gateways play a vital role in the online transaction process, which is the realisation of value, and hence are seen as an important pillar of ecommerce.
    `,
  },
  {
    "question": "Is there any setup fee or annual maintainance fee that I need to pay regularly?",
    "answer": `
    A payment gateway is an ecommerce service that processes online payments for online as well as offline businesses. Payment gateways help accept payments by transferring key information from their merchant websites to issuing banks, card associations and online wallet players.
    Payment gateways play a vital role in the online transaction process, which is the realisation of value, and hence are seen as an important pillar of ecommerce.
    `,
  }

]
export default function FAQ() {
  const [FAQChooseQue, setFAQChooseQue] = React.useState(FAQQuestions[0].question)
  const [FAQChooseAns, setFAQChooseAns] = React.useState(FAQQuestions[0].answer)
  return (
    <div
      style={{ minHeight: "50vh" }}
    >
      <div style={{ position: "absolute" }}
        className="second-heading-style center-style"
      >
        <div className="" style={{ textAlign: "center" }}>
          <h1 style={{ color:"#fff", fontWeight: "bold", marginBottom: "10px" }}>FAQs</h1>
        </div>
      </div>
      <img src="/assets/images/dice2.png"
        className="second-heading-style"
      />
      <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "40px" }}>
        <div className="col-md-6 col-12 faq-question"
        >
          {FAQQuestions.map((item, index) => (
            <div style={{ display: "flex", padding: "20px 0",cursor:"pointer" }}
              onClick={() => {
                setFAQChooseAns(item.answer)
                setFAQChooseQue(item.question)
              }}
            >
              <div style={{ width: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <i className="fa fa-circle" />
              </div>
              <div style={{ width: "80%" }}>
                {item.question}
              </div>
              <div style={{ width: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <i className="fa fa-chevron-right" />
              </div>
            </div>
          ))}

        </div>
        <div className="col-md-6 col-12 faq-answer"
        >
          <div>
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {FAQChooseQue}
            </div>
            <div style={{ fontSize: "18px",textAlign:"justify" }}>
              {FAQChooseAns}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { useEffect } from 'react';
import './App.css';
import './styles/commonStyles.css'
import './styles/homeStyles/max990.css'

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";


import store from './redux/store';
import { getUser } from './redux/action';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Header from './Components/Header';
import Footer from './Components/Footer';
import NotFound from './Components/NotFound';
import HowToPlay from './Path/HowToPlay';
import Partners from './Path/Partners';
import FAQ from './Path/FAQ';
import LandingPage from './Path/LandingPage';
import { colors } from './Components/colors';
import LotteryDetails from './Path/LotteryDetails';
import Dashboard from './Path/Profile/Dashboard';

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AppMain />
      </Provider>
    );
  }
}
function AppMain() {
  const dispatch = useDispatch()
  const LoginUser = useSelector((state) => state.LoginState);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getUser())
    }, 100)
   
  }, [])
  return (
    <div style={{ overflowX: 'hidden',backgroundColor:colors.color_green4 }}>
      {!LoginUser.isLoading &&
        <Router>
          <div>
            <Header />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/how-to-play" element={<HowToPlay />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/lottery-details" element={<LotteryDetails />} />
              <Route path="/dashboard" element={<Dashboard />} />
              
              <Route path="/*" element={<NotFound />} />
            </Routes>
            <Footer/>
          </div>
        </Router>

      }
    </div>
  )

}
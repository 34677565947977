import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../../Components/colors';

export default function Dashboard(props) {
  const StackBlock = () => {
    return (
      <div style={{}}>
        <div className="row" style={{ width: "250px", margin: "0 15px 0 0", }}>
          <div className="col-5" style={{
            alignItems: "center",
            backgroundColor: colors.color_green1,
            borderRadius: "30px ",
            height: "170px",
            width: "130px",
            border: "1px solid #999",
            margin: "0px -25px 0 0"
          }}>
            <div style={{
              paddingLeft: "30px",
              fontSize: "30px",
              color: "#fff",
              fontWeight: "bolder"
            }}>A.1</div>
          </div>
          <div className="col-6" style={{
            alignItems: "center",
            borderRadius: "0 30px 30px 0 ",
            height: "170px",
            width: "140px",
            padding: "0 40px",
            border: "1px solid #999",
            borderLeft: 0,
          }}>
            <div style={{
              fontSize: "18px",
              color: colors.color_green1,
              fontWeight: "bolder"
            }}>#2444</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ minHeight: "50vh", marginBottom: "30px" }} >
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-10">
          <div className="py-4">
            <div className="lottery-details-list-heading">Stacked Tickets:</div>
          </div>
          <div style={{ backgroundColor: "#fff", borderRadius: "30px", padding: "20px" }}>
            <div style={{ display: "flex", overflowX: "scroll" }} className="scroll_winners">
              <StackBlock />
              <StackBlock />
              <StackBlock />
              <StackBlock />
              <StackBlock />
              <StackBlock />
              <StackBlock />
              <StackBlock />
              <StackBlock />
              <StackBlock />
            </div>
          </div>
          <div className="py-4">
            <div className="lottery-details-list-heading">Running Games:</div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ backgroundColor: "#fff", borderRadius: "30px", padding: "20px" }}>
              <div style={{ display: "flex", overflowX: "scroll" }} className="scroll_winners">
                <div style={{ display: "flex" }}>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    backgroundColor: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#fff",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>A</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>B</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>C</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>D</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green1,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>|</div>

                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    backgroundColor: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#fff",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>1</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>2</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>3</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>4</div>
                  <div style={{
                    height: "45px", width: "45px",
                    alignItems: "center", justifyContent: "center", display: "flex",
                    color: colors.color_green2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "10px", cursor: 'pointer'
                  }}>5</div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-4">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="col-md-8 p-5" style={{ backgroundColor: "#fff", borderRadius: "30px", padding: "20px" }}>

                <div className="row">
                  <div className="col-md-6">
                    <div className="lottery-details-list-heading">Lottery Number:</div>
                    <div className="row">
                      <div className="col-6" style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: colors.color_green1,
                        borderRadius: "30px ",
                        height: "150px",
                        border: "1px solid #999",
                        margin: "0px -25px 0 0"
                      }}>
                        <div style={{
                          paddingLeft: "30px",
                          fontSize: "30px",
                          color: "#fff",
                          fontWeight: "bolder"
                        }}>A.1</div>
                      </div>
                      <div className="col-6" style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "0 30px 30px 0 ",
                        height: "150px",
                        padding: "0 40px",
                        border: "1px solid #999",
                        borderLeft: 0
                      }}>
                        <div style={{
                          fontSize: "18px",
                          color: colors.color_green1,
                          fontWeight: "bolder"
                        }}>#2444</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="lottery-details-list-heading">Prize Pool:</div>
                    <div style={{
                      // backgroundColor: colors.color_green3,
                      borderRadius: "30px",
                      height: "150px",
                      border: "1px solid #999",
                    }}>
                      <div className="row">
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <img src="assets/images/prize.png" style={{ width: "50px" }} />
                        </div>
                        <div className="lottery-price-container"
                          style={{
                            backgroundColor: colors.color_green3,
                            borderRadius: "30px 0 0 30px",
                            border: "1px solid #999",
                            margin: "0px -40px 20px 0",
                          }}>
                          <div>
                            <div className="lottery-prize-font-1">20</div>
                            <div className="lottery-prize-font-2">Eth</div>
                            <div style={{ paddingTop: "7px" }} className="lottery-prize-font-1">1st</div>
                          </div>
                        </div>
                        <div className="lottery-price-container"
                          style={{
                            backgroundColor: colors.color_green2,
                            borderRadius: "30px 0 0 30px",
                            border: "1px solid #999",
                            margin: "0px -40px 20px 0",
                          }}>
                          <div>
                            <div className="lottery-prize-font-1">10</div>
                            <div className="lottery-prize-font-2">Eth</div>
                            <div style={{ paddingTop: "7px" }} className="lottery-prize-font-1">2nd</div>
                          </div>
                        </div>
                        <div className="lottery-price-container"
                          style={{
                            backgroundColor: colors.color_green1,
                            borderRadius: "30px ",
                            border: "1px solid #999",
                            margin: "0px 0px 20px 0",
                            justifyContent: "center"
                          }}>
                          <div>
                            <div className="lottery-prize-font-1">5</div>
                            <div className="lottery-prize-font-2">Eth</div>
                            <div style={{ paddingTop: "7px" }} className="lottery-prize-font-1">3rd</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div style={{ border: "1px dotted black", margin: "20px 0", padding: "20px", borderRadius: "20px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <div>
                          <div style={{ textAlign: "center" }}>
                            <img style={{ height: "100px" }} src="/assets/images/howtolottery.png" />
                          </div>
                          <div style={{ textAlign: "center", fontSize: "40px", fontWeight: "bold" }}>120</div>
                          <div style={{ textAlign: "center", fontSize: "20px" }}>Available Seat: </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <div style={{ textAlign: "center" }}>
                            <img style={{ height: "100px" }} src="/assets/images/time.png" />
                          </div>
                          <div style={{ textAlign: "center", fontSize: "40px", fontWeight: "bold" }}>2 hrs</div>
                          <div style={{ textAlign: "center", fontSize: "20px" }}>Time Remaining: </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <div style={{ textAlign: "center" }}>
                            <img style={{ height: "100px" }} src="/assets/images/howtoTicket.png" />
                          </div>
                          <div style={{ textAlign: "center", fontSize: "40px", fontWeight: "bold" }}>10</div>
                          <div style={{ textAlign: "center", fontSize: "20px" }}>Ticket Cost: </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="center-style">
                    <div style={{
                      backgroundColor: colors.color_green2,
                      padding: "10px 40px",
                      color: "#fff",
                      fontSize: "20px",
                      fontWeight: "bold",
                      borderRadius: "20px", cursor: 'pointer'
                    }} className="get-in">
                      Get In
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link className="Link" to="/lottery-details">
                      <div style={{
                        backgroundColor: colors.color_green2,
                        padding: "6px 20px",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "20px"
                      }} className="get-in">
                        Learn More
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="p-4">
              <div className="row">
                <div className="col-md-4">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                      border: "1px solid #999",
                      height: "600px", position: "relative"
                    }} className="col-12">
                      <div style={{ position: "absolute" }} className="col-12">
                        <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <img style={{ width: "150px", }} src="/assets/images/howtoTicket.png"
                          />
                        </div>
                      </div>
                      <div className="col-12" style={{
                        position: "absolute",
                        backgroundColor: `${colors.color_green2}99`,
                        borderRadius: "30px",
                        border: "1px solid #999",
                        marginTop: "200px",
                        height: "400px"
                      }}>
                        <div style={{ position: "absolute" }} className="col-12">
                          <div style={{
                            height: "100px", display: "flex", justifyContent: "center", alignItems: "center",
                            fontWeight: "bold", fontSize: "20px", color: "#fff"
                          }}>
                            price: .05 Eth
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div className="col-12" style={{
                            position: "absolute",
                            backgroundColor: `${colors.color_green2}bb`,
                            borderRadius: "30px",
                            border: "1px solid #999",
                            marginTop: "100px",
                            height: "300px"
                          }}>
                            <div style={{
                              padding: "10px 40px",
                              height: "300px", display: "flex", justifyContent: "center", alignItems: "center",
                              fontSize: "16px", color: "#fff", textAlign: "center"
                            }}>
                              iAnd scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap, remaining essentially unchanged. It was popularised in the 1960s with the release of Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div style={{
                    display: "flex",
                    height: "600px", justifyContent: "center", alignItems: 'center'
                  }}>
                    <div>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="shadow"
                          style={{
                            margin: "10px",
                            backgroundColor: colors.color_green2,
                            width: "40px",
                            height: "40px", display: "flex", justifyContent: "center", alignItems: "center",
                            fontWeight: "25px",
                            color: "#fff", borderRadius: "10px", cursor: 'pointer'
                          }}>
                          <i className="fa fa-plus" />
                        </div>
                        <div className="shadow"
                          style={{
                            margin: "10px",
                            padding: "0 40px",
                            backgroundColor: colors.color_green3,
                            height: "40px", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center",
                          }}
                        >
                          100
                          <img src="assets/images/howtoTicket.png" style={{ width: "30px", margin: "0 0 0 20px" }} />
                        </div>
                        <div style={{
                          margin: "10px",
                          backgroundColor: colors.color_green2,
                          width: "40px",
                          height: "40px", display: "flex", justifyContent: "center", alignItems: "center",
                          fontWeight: "25px",
                          color: "#fff", borderRadius: "10px", cursor: 'pointer'
                        }}>
                          <i className="fa fa-minus" />
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", margin: "30px" }}>
                        <div style={{
                          backgroundColor: colors.color_green2,
                          fontWeight: "bold",
                          fontSize: "30px",
                          padding: "10px 70px",
                          color: "#fff", cursor: 'pointer'
                        }}>
                          MINT
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", margin: "90px 0" }}>
                        <div style={{
                          fontWeight: "bold",
                          fontSize: "30px",
                        }}>
                          OR,
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", margin: "30px" }}>
                        <div style={{
                          backgroundColor: colors.color_green2,
                          fontSize: "25px",
                          padding: "10px 70px",
                          color: "#fff",
                          textAlign: "center", cursor: 'pointer'
                        }}>
                          Buy at OpenSea
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="assets/images/bigWin.png"
                    style={{ height: "80vh" }}
                  />
                  {/* <div style={{
                    display: "flex",
                    height: "600px", justifyContent: "center", alignItems: 'center'
                  }}>
                    <div style={{
                      fontSize: "25px",
                      padding: "10px 70px",
                      textAlign: "center"
                    }}>
                      Lorem Ipsum Ss
                      Simply Dummy Text
                      Printing,
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
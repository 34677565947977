import React from 'react';
import { colors } from '../../../Components/colors';

export default function Winners(props) {
  const WinnerBlock = () => {
    return (
      <div>
        <div style={{ width: "270px", padding: "1vw",paddingTop:"20px", position: "relative" }}>
          <div style={{ position: "absolute", margin: "-20px 120px" }}>
            <div style={{ position: "absolute" }}>
              <img src="assets/images/reward.png"
                style={{ width: "50px", height: "50px", marginTop: "80px" }}
              />

            </div>
            <img src="assets/images/Avatar.png"
              style={{ width: "130px", height: "110px" }}
            />
          </div>
          <div style={{ backgroundColor: "#FFF6CB", width: "220px", borderRadius: "30px", padding: "30px" }}>
            <div style={{ fontSize: "18px", color: colors.color_green1, fontWeight: "bolder" }}>#22224</div>
            <div style={{ width: "6vw" }}>
              <div style={{ fontSize: "27px", color: colors.color_green1, fontWeight: "bolder" }}>12th Eth</div>
            </div>
            <div style={{ fontSize: "16px", color: colors.color_green1, textAlign: "center" }}>View on Etherscan</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ minHeight: "50vh",overflowX:"hidden" }} className="center-style">

      <div style={{ margin: "30px", padding: "40px 20px 20px", backgroundColor: "#fff", borderRadius: "30px", width: "90%" }}>
        <div style={{ position: "absolute" }}>
          <div style={{ 
            margin: "-50px 0 0 30vw",
             display: "flex", alignItems: "center", width: "100%" }}>
            <div style={{ borderRadius: "20px", color: colors.color_green5, fontWeight: "bold", fontSize: "30px", backgroundColor: colors.color_green2, height: "40px", width: "30vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ fontSize: "16px", }}>
                <img src="/assets/images/winner.png" style={{width:"25px"}} />   Winners
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: colors.color_green2, borderRadius: "30px", padding: "20px" }}>
          <div style={{ display: "flex", overflowX: "scroll" }} className="scroll_winners">
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
            <WinnerBlock />
          </div>
        </div>
      </div>
    </div>
  )
}

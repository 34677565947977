import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/commonStyles.css';

export default function Footer() {
  return (
    <div className="footer" >
      <div className="row">
        {/* <div className="col-lg-2 col-sm-4 col-12"> */}
        <div className="footer-container-1">
          <div className="center-style" style={{ paddingTop: "20px" }}>
            <img src={'assets/images/logo.png'}
              className="footer-image"
            />
          </div>
        </div>
        <div className="footer-container-2">
          {/* <div className="col-lg-3 col-sm-4 col-12 footer-text"> */}
          <div className="footer-text">
            <div style={{ textAlign: "justify" }}>
              but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </div>
          </div>
        </div>
        <div className="footer-container-3">
          {/* <div className="col-lg-2 col-sm-4 col-6 "> */}
          <div className="footer-text">
            <div style={{ paddingBottom: "10px", }}>We are social</div>
            <div className="row">
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-facebook" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-twitter" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-telegram" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-google" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-linkedin" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-pinterest" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-skype" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-instagram" />
                </div>
              </div>
              <div className="col-4 p-2" >
                <div className="round-icon">
                  <i className="fa fa-twitter" />
                </div>
              </div>
            
            </div>
          </div>
        </div>
        <div className="footer-container-4">
          {/* <div className="col-lg-1 col-sm-4 col-6"> */}
          <div className="footer-text">
            <div>Join the community</div>
            <div className="footer-text-style">Discord +</div>
            <div className="footer-text-style">Telegram +</div>
          </div>
        </div>
        <div className="footer-container-5">
          {/* <div className="col-lg-2 col-sm-4 col-6"> */}
          <div className="footer-text">
            <div className="footer-text-style">Documentation</div>
            <div className="footer-text-style">Home</div>
            <div className="footer-text-style">Mint</div>
            <div className="footer-text-style">Whitepaper</div>
            <div>
              <Link to="/partners" className="footer-text-style">Partners</Link>
            </div>
          </div>
        </div>

        <div className="footer-container-6">
          {/* <div className="col-lg-2 col-sm-4 col-6"> */}
          <div className="footer-text">
            <div className="footer-text-style">Hall of Fame</div>
            <div className="footer-text-style">Home</div>
            <div className="footer-text-style">Take Part</div>
            <div className="footer-text-style">Mint Ticket</div>
          </div>
        </div>

      </div>
      <div style={{ textAlign: "center", width: "100vw", padding: "10px" }}>
        All Rights Reserved © To The-NFT-lottery.com
      </div>
    </div>
  )
}
import React from 'react';
import { colors } from '../../../Components/colors';

const array = [
  {
    prize: "10",
    Name: "A.1",
    id: "2224",
    time: "2.5",
  },
  {
    prize: "1.10",
    Name: "A.2",
    id: "2225",
    time: "2",
  },
  {
    prize: "9.10",
    Name: "A.3",
    id: "2226",
    time: "2",
  },
  {
    prize: "1.10",
    Name: "B.1",
    id: "2227",
    time: "2",
  },
  {
    prize: "1.10",
    Name: "B.2",
    id: "2228",
    time: "2",
  },
  {
    prize: "1.10",
    Name: "B.3",
    id: "2229",
    time: "2",
  },
]
export default function CurrentRunningGames(props) {
  const CurrentRunningGameBlock = ({
    prize,
    name,
    id,
    time,
  }) => {
    return (
      <div>
        <div style={{ backgroundColor: "#FFF6CB", width: "100%", borderRadius: "30px 30px 0 0", padding: "20px 20px 50px", margin: "20px 0 0" }}>
          <div style={{ display: "inline" }}>
            <div style={{ display: "inline-block", width: "50%" }}>
              <div style={{ marginLeft: "30px", fontSize: "35px", color: colors.color_green1, fontWeight: "bolder" }}>{name}</div>
            </div>
            <div style={{ display: "inline-block", width: "50%", textAlign: "right" }}>
              <div style={{ fontSize: "18px", color: colors.color_green1, fontWeight: "bolder" }}>#{id}</div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: colors.color_green5, width: "100%", borderRadius: "30px", padding: "20px", margin: "-25px  0 20px", border: "1px solid #999" }}>
          <div className="row">
            <div className="col-6" style={{ textAlign: "center" }}>
              <img src="/assets/images/ethereum.png"
                style={{ width: "40px", height: "70px", objectFit: "contain" }}
              />
              <div style={{ fontSize: "30px" }}>{prize}</div>
              <div style={{ fontSize: "14px", fontWeight: "bold" }}>Pool Prize</div>
            </div>
            <div className="col-6" style={{ textAlign: "center" }}>
              <img src="/assets/images/time.png"
                style={{ width: "70px", height: "70px", objectFit: "contain" }}
              />
              <div style={{ textAlign: "center", fontSize: "30px" }}>{time} hrs</div>
              <div style={{ fontSize: "14px", fontWeight: "bold" }}>Time Remaining:</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ minHeight: "50vh" }} className="center-style">

      <div style={{ margin: "30px", padding: "40px 20px 20px", backgroundColor: "#fff", borderRadius: "30px", width: "90%" }}>
        <div style={{ position: "absolute" }}>
          <div style={{ margin: "-50px 0 0 30vw ", display: "flex", alignItems: "center", width: "100%" }}>
            <div style={{ borderRadius: "20px", color: colors.color_green5, fontWeight: "bold", fontSize: "30px", backgroundColor: colors.color_green2, height: "40px", width: "30vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ fontSize: "16px", }}>
                Current Running Games
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: colors.color_green2, borderRadius: "30px", padding: "20px",display: "flex",alignItems:"center" }}>
          <div style={{  }}>
            <div className="row">
              {array.map((item, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <CurrentRunningGameBlock
                    prize={item.prize}
                    name={item.Name}
                    id={item.id}
                    time={item.time}
                  />
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import { colors } from '../../Components/colors';
export default function Payouts() {
  return (
    <div>
      <table>
        <thead>
          <tr >
            <th colSpan={6} style={{ backgroundColor: "#444" }} className="games-class">Grand Prizes</th>
          </tr>
          <tr >
            <th style={{ backgroundColor: "#444" }} className="entrants">Entrants</th>
            <th style={{ backgroundColor: "#444" }} className="payouts">Payouts</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">A</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">B</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">C</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">D</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="entrants">Grand Prize</td>
            <td className="payouts">10% of the total Eth entered into game,as represented by the number of NFTs entered into the game</td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
          </tr>
          <tr>
            <td className="entrants">Runner Up</td>
            <td className="payouts">8.5% of the total Eth entered into game,as represented by the number of NFTs entered into the game</td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
          </tr>
          <tr>
            <td className="entrants">2nd Runner Up</td>
            <td className="payouts">7% of the total Eth entered into game,as represented by the number of NFTs entered into the game</td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
          </tr>


        </tbody>
      </table>

      <table>
        <thead>
          <tr >
            <th colSpan={6} style={{ backgroundColor: "#444" }} className="games-class">Other Payouts</th>
          </tr>
          <tr >
            <th style={{ backgroundColor: "#444" }} className="entrants">Entrants</th>
            <th style={{ backgroundColor: "#444" }} className="payouts">Payouts</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">A</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">B</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">C</th>
            <th style={{ backgroundColor: "#444" }} className="column-a">D</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="entrants">Top 1/10th</td>
            <td className="payouts">3x of 1 entry, paid out in Eth</td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-minus" /></td>
            <td className="column-a"><i className="fa fa-minus" /></td>
            <td className="column-a"><i className="fa fa-minus" /></td>
          </tr>
          <tr>
            <td className="entrants">Top 1/3rd</td>
            <td className="payouts">Cumulative 30% of total Ethereum entered into the game,<br />divided evenly between the entrants<br />(roughly 3x of 1 entry)</td>
            <td className="column-a"><i className="fa fa-minus" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
          </tr>
          <tr>
            <td className="entrants">Bottom 2/3rds</td>
            <td className="payouts">Cumulative 20% of total Ethereum entered into the game,<br />divided evenly between the entrants<br />(roughly 1x of 1 entry)</td>
            <td className="column-a"><i className="fa fa-minus" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
            <td className="column-a"><i className="fa fa-check" /></td>
          </tr>


        </tbody>
      </table>


      <style jsx>{`
      table {
        font-family: arial, sans-serif;
        border: 0.5px solid #eee;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #000;
        text-align: left;
        padding: 5px 8px;
      }
      
      th {
        background-color: #fff;
        border: 1.5px solid #000;
      }
      
      thead {
        background-color: #888;
        color: #fff;
      }
      
      tbody {
        border: 1.5px solid #000;
      }
      
      tr:nth-child(odd) {
        background-color: #ddd;
        overflow-x: scroll;
      }
      
      tr:nth-child(even) {
        background-color: #fff;
        overflow-x: scroll;
      }
      .entrants{
        text-align:center;
      }
      .column-a{
        text-align:center;
      }
      .payouts{
        width:50%;
        text-align:center;
      }
      
      `}</style>

    </div>
  )
}

import React from 'react';
import { colors } from '../../Components/colors';
const tableData = [
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
  {
    Num: "#12",
    Address: "0xFeB4e.......b270FdA7ab93fCBf8",
    TicketAmount: "5 Tickets",
    time: "5 min Ago"
  },
]
export default function LotteryDetails() {
  return (
    <div
      style={{ minHeight: "50vh" }}
    >
      <div
        className="second-heading-style center-style"
      >
        <div className="" style={{ textAlign: "center" }}>
          <h1 style={{ color: "#490E67", fontWeight: "bold", marginBottom: "10px", fontSize: "25px" }}>A decentalized lottery for all the degens</h1>
          <input className="contact-input col-12 p-3"
            placeholder="Search Any"
            style={{ fontSize: "14px" }}
          />
        </div>
      </div>
      <div className="m-5 p-5"
        style={{ backgroundColor: "#fff", borderRadius: "50px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <div className="lottery-details-list-heading">Lottery Number:</div>
            <div className="row">
              <div className="col-6" style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: colors.color_green1,
                borderRadius: "30px ",
                height: "150px",
                border: "1px solid #999",
                margin: "0px -25px 0 0"
              }}>
                <div style={{
                  paddingLeft: "30px",
                  fontSize: "30px",
                  color: "#fff",
                  fontWeight: "bolder"
                }}>A.1</div>
              </div>
              <div className="col-6" style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "0 30px 30px 0 ",
                height: "150px",
                padding: "0 40px",
                border: "1px solid #999",
                borderLeft: 0
              }}>
                <div style={{
                  fontSize: "18px",
                  color: colors.color_green1,
                  fontWeight: "bolder"
                }}>#2444</div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="lottery-details-list-heading">Time:</div>
            <div style={{
              backgroundColor: colors.color_green3,
              borderRadius: "30px",
              height: "150px",
              border: "1px solid #999",
            }}>
              <div className="row col-12">
                <div className="col-6" style={{ paddingTop: "20px" }}>
                  <div className="lottery-details-time">Start</div>
                  <div className="lottery-details-time">9:30:11 AM</div>
                  <div className="lottery-details-time">07/04/2022</div>
                  <div className="lottery-details-time">GMT +0.00 </div>
                </div>
                <div className="col-6" style={{ paddingTop: "20px" }}>
                  <div className="lottery-details-time">End</div>
                  <div className="lottery-details-time">9:30:11 AM</div>
                  <div className="lottery-details-time">07/04/2022</div>
                  <div className="lottery-details-time">GMT +0.00 </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="lottery-details-list-heading">Prize Pool:</div>
            <div style={{
              // backgroundColor: colors.color_green3,
              borderRadius: "30px",
              height: "150px",
              border: "1px solid #999",
            }}>
              <div className="row">
                <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src="assets/images/prize.png" style={{ width: "50px" }} />
                </div>
                <div className="lottery-price-container"
                  style={{
                    backgroundColor: colors.color_green3,
                    borderRadius: "30px 0 0 30px",
                    border: "1px solid #999",
                    margin: "0px -40px 20px 0",
                  }}>
                  <div>
                    <div className="lottery-prize-font-1">20</div>
                    <div className="lottery-prize-font-2">Eth</div>
                    <div style={{ paddingTop: "7px" }} className="lottery-prize-font-1">1st</div>
                  </div>
                </div>
                <div className="lottery-price-container"
                  style={{
                    backgroundColor: colors.color_green2,
                    borderRadius: "30px 0 0 30px",
                    border: "1px solid #999",
                    margin: "0px -40px 20px 0",
                  }}>
                  <div>
                    <div className="lottery-prize-font-1">10</div>
                    <div className="lottery-prize-font-2">Eth</div>
                    <div style={{ paddingTop: "7px" }} className="lottery-prize-font-1">2nd</div>
                  </div>
                </div>
                <div className="lottery-price-container"
                  style={{
                    backgroundColor: colors.color_green1,
                    borderRadius: "30px ",
                    border: "1px solid #999",
                    margin: "0px 0px 20px 0",
                    justifyContent: "center"
                  }}>
                  <div>
                    <div className="lottery-prize-font-1">5</div>
                    <div className="lottery-prize-font-2">Eth</div>
                    <div style={{ paddingTop: "7px" }} className="lottery-prize-font-1">3rd</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div style={{ marginTop: "30px" }} className="lottery-details-list-heading">Participates:</div>
        <div className="row">
          <div className="col-lg-6">
            <table>
              <thead style={{ borderRadius: "20px" }}>
                <tr style={{ backgroundColor: colors.color_green1, color: "#fff", }}
                >
                  <th>Num</th>
                  <th>Address</th>
                  <th>Ticket Amount</th>
                  <th>Time</th>
                </tr>
              </thead>
              {tableData.map((item, index) => (
                <tr>
                  <td>{item.Num}</td>
                  <td>{item.Address}</td>
                  <td>{item.TicketAmount}</td>
                  <td>{item.time}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className="col-lg-6 ">
            <table>
              <thead style={{ borderRadius: "20px" }}>
                <tr style={{ backgroundColor: colors.color_green1, color: "#fff", }}
                >
                  <th>Num</th>
                  <th>Address</th>
                  <th>Ticket Amount</th>
                  <th>Time</th>
                </tr>
              </thead>
              {tableData.map((item, index) => (
                <tr>
                  <td>{item.Num}</td>
                  <td>{item.Address}</td>
                  <td>{item.TicketAmount}</td>
                  <td>{item.time}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>

      </div >

      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-10">
          <div className="py-4">
            <div className="row">
              <div className="col-md-4">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    backgroundColor: "#fff",
                    borderRadius: "30px",
                    border: "1px solid #999",
                    height: "600px", position: "relative"
                  }} className="col-12">
                    <div style={{ position: "absolute" }} className="col-12">
                      <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img style={{ width: "150px", }} src="/assets/images/howtoTicket.png"
                        />
                      </div>
                    </div>
                    <div className="col-12" style={{
                      position: "absolute",
                      backgroundColor: `${colors.color_green2}99`,
                      borderRadius: "30px",
                      border: "1px solid #999",
                      marginTop: "200px",
                      height: "400px"
                    }}>
                      <div style={{ position: "absolute" }} className="col-12">
                        <div style={{
                          height: "100px", display: "flex", justifyContent: "center", alignItems: "center",
                          fontWeight: "bold", fontSize: "20px", color: "#fff"
                        }}>
                          price: .05 Eth
                        </div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <div className="col-12" style={{
                          position: "absolute",
                          backgroundColor: `${colors.color_green2}bb`,
                          borderRadius: "30px",
                          border: "1px solid #999",
                          marginTop: "100px",
                          height: "300px"
                        }}>
                          <div style={{
                            padding: "10px 40px",
                            height: "300px", display: "flex", justifyContent: "center", alignItems: "center",
                            fontSize: "16px", color: "#fff", textAlign: "center"
                          }}>
                            iAnd scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap, remaining essentially unchanged. It was popularised in the 1960s with the release of Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div style={{
                  display: "flex",
                  height: "600px", justifyContent: "center", alignItems: 'center'
                }}>
                  <div>
                    <div style={{ display: "flex", justifyContent: "center", margin: "30px" }}>
                      <div style={{
                        backgroundColor: colors.color_green2,
                        fontSize: "25px",
                        padding: "10px 70px",
                        color: "#fff", textAlign: "center",
                        borderRadius:"20px"
                      }}>
                        <img src="assets/images/howtoTicket.png" /><br />
                        MINT 25 & Participate
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", margin: "90px 0" }}>
                      <div style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                      }}>
                        OR,
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", margin: "30px" }}>
                      <div style={{
                        backgroundColor: colors.color_green2,
                        fontSize: "25px",
                        padding: "30px 70px",
                        color: "#fff",
                        textAlign: "center",
                        borderRadius:"20px"
                      }}>
                        Participate
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <img src="assets/images/bigWin.png"
                    style={{ height: "80vh" }}
                  />
                {/* <div style={{
                  display: "flex",
                  height: "600px", justifyContent: "center", alignItems: 'center'
                }}>
                  <div style={{
                    fontSize: "25px",
                    padding: "10px 70px",
                    textAlign: "center"
                  }}>
                    Lorem Ipsum Ss
                    Simply Dummy Text
                    Printing,
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: "50vw", margin: "0 25vw" }}>
        <div style={{ margin: "70px 0 0", textAlign: "center", fontSize: "20px", color: "#490E67", fontWeight: "800" }}>
          Winners Are Always Selected Randomly Using Chainlink VRF
        </div>
        <div style={{ textAlign: "right", fontSize: "16px", color: "#999" }}>
          Learn More {">>"}
        </div>
      </div>
      <div>
        <img src="/assets/images/BigWin2.png"
          style={{ width: "100vw" }}
        />
      </div>
      <style jsx>{`
      table {
        font-family: arial, sans-serif;
        border: 0.5px solid #eee;
        width: 100%;
          -moz-border-radius:10px;
          -webkit-border-radius:10px;
          border-radius:10px
      }
      
      td {
        background-color:#ccfccb;
        text-align: left;
        padding: 5px 8px;
      }
      
      th {
        padding: 5px 8px;
        text-align: left;
      }
      
      thead {
        color: #fff;
        border-radius:30px;
      }
      
      tbody {
      }
      
     

      `}</style>
    </div >
  )
}